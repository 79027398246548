<template>
  <div>
    <slot
      name="activator"
      v-bind="{show}"
    />
    <b-modal
      v-model="display"
      title="Пополнение баланса"
      hide-footer
      centered
      no-body
      body-class="p-0"
      size="xl"
    >
      <template #default="{ hide }">
        <profile-editor
          v-if="user"
          class="p-2"
          :user="user"
          @close="hide"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'
import ProfileEditor from '../ProfileEditor.vue'

export default {
  name: 'ProfileEditModal',
  components: {
    ProfileEditor,
    BModal,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    display: false,
  }),
  methods: {
    show() {
      this.display = true
    },
  },
}
</script>

<style scoped>

</style>
