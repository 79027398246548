<template>
  <b-card
    class="mb-0 full-height"
    no-body
  >
    <div class="card-body d-flex flex-column flex-xl-row justify-content-between align-items-xl-center">
      <div class="truncate order-1 order-xl-0 text-center text-xl-left mt-1 mt-xl-0">
        <h2 class="mb-25 font-weight-bolder">
          {{ number }}
        </h2>
        <span>{{ title }}</span>
      </div>
      <div
        class="b-avatar rounded-circle order-0 order-xl-1 ml-auto mr-auto m-xl-0"
        style="width: 45px; height: 45px;"
        :class="color ? `badge-light-${color}` : 'badge-light-primary'"
      >
        <span class="b-avatar-custom">
          <feather-icon
            size="25"
            :icon="icon"
          />
        </span>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'

export default {
  name: 'NumberWithIcon',
  components: {
    BCard,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: null,
    },
    number: {
      type: [Number, String],
      default: 0,
    },
    title: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
