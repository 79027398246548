<template>
  <div>
    <b-row class="mb-2">
      <b-col class="col-12 col-md-6 col-xl-4">
        <number-with-icon
          :number="widgets.total"
          icon="UsersIcon"
          :title="$t('page.admin_students.widgets.total')"
          color="primary"
        />
      </b-col>
      <b-col class="col-12 col-md-6 col-xl-4">
        <number-with-icon
          :number="widgets.student_leads"
          icon="LinkIcon"
          title="Ученики по заявкам"
          color="primary"
        />
      </b-col>
      <b-col class="col-12 col-md-6 col-xl-4">
        <number-with-icon
          :number="widgets.student_tutor"
          icon="HeartIcon"
          title="Ученики репетиторов"
          color="primary"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card
          no-body
          class="mb-0"
        >
          <table-header
            search
            subjects
            :filter="filter"
            :search-updated="getStudents"
            student-type
          />
          <b-table
            ref="teachersTable"
            striped
            :items="students"
            responsive
            :fields="tableFields"
            primary-key="id"
            show-empty
            :empty-text="$t('page.students.search.labels.no-items')"
            :sort-by.sync="filter.sortBy"
            :sort-desc.sync="filter.sortDesc"
          >
            <template #cell(username)="{item, value}">
              <div class="d-flex flex-row justify-content-start align-items-center">
                <store-image
                  v-if="item.user.image"
                  :store-id="item.user.image.full_url"
                  wrapper-class="table-avatar"
                />
                <b-avatar
                  v-else
                  size="25px"
                />
                <span class="d-block ml-1">{{ value }}</span>
              </div>
            </template>
            <template #cell(teachers)="{item}">
              <div class="d-flex flex-row justify-content-start align-items-center">
                <template v-if="item.user.teacherStudents">

                  <profile-edit-modal
                    ref="editModal"
                    :user="item.user.teacherStudents.teacher"
                  >
                    <template #activator="{show}">
                      <a
                        href="#"
                        :title="fullTeacherName(item.user.teacherStudents.teacher)"
                        @click="show"
                      >
                        <store-image
                          v-if="item.user.teacherStudents.teacher.image"
                          :store-id="item.user.teacherStudents.teacher.image.full_url"
                          wrapper-class="table-avatar"
                        />
                        <b-avatar
                          v-else
                          size="25px"
                        />
                      </a>
                    </template>
                  </profile-edit-modal>
                </template>

              </div>
            </template>
          </b-table>
          <table-footer
            :pagination-updated="getStudents"
            :pagination="pagination"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BTable, BCol, BRow, BAvatar,
} from 'bootstrap-vue'
import StoreImage from '@/components/store/StoreImage.vue'
import NumberWithIcon from '@/components/widgets/stats/NumberWithIcon.vue'
import TableFooter from '@/components/page-elements/table/TableFooter.vue'
import TableHeader from '@/components/page-elements/table/TableHeader.vue'
import { getUserFullName } from '@/helpers'
import ProfileEditModal from '../../../../components/profile/modals/ProfileEditModal.vue'

export default {
  name: 'Students',
  components: {
    ProfileEditModal,
    TableHeader,
    TableFooter,
    NumberWithIcon,
    StoreImage,
    BCard,
    BTable,
    BCol,
    BRow,
    BAvatar,
  },
  data: () => ({
    students: [],
    pagination: {
      perPage: 15,
      perPageOptions: [5, 15, 30],
      totalCount: null,
      currentPage: 1,
    },
    widgets: {
      total: 0,
      student_leads: 0,
      student_tutor: 0,
    },
    filter: {
      search: '',
      sortBy: null,
      sortDesc: false,
      subject_ids: null,
      studentType: '',
    },
  }),
  computed: {
    sortBy() {
      if (!this.filter.sortBy) return null
      let { sortBy } = this.filter
      if (this.filter.sortDesc) sortBy = `-${sortBy}`
      else sortBy = `+${sortBy}`
      return sortBy
    },
    tableFields() {
      return [
        {
          key: 'username',
          label: this.$t('page.admin_students.table.labels.name'),
          sortable: false,
          formatter: (value, key, student) => getUserFullName(student.user),
        },
        {
          key: 'user.phone',
          label: this.$t('page.admin_students.table.labels.phone'),
          sortable: false,
        },
        {
          key: 'user.created',
          label: this.$t('page.admin_students.table.labels.created_at'),
          sortable: true,
          formatter: (value, key, student) => (student.created ? this.$options.filters.dateTime(student.created) : this.$t('no_data')),
        },
        {
          key: 'teachers',
          label: 'Учителя',
          sortable: false,
        },
      ]
    },
  },
  watch: {
    sortBy() {
      this.getStudents()
    },
  },
  async created() {
    await this.getStudents()
    await this.getWidgetsInfo()
  },
  methods: {
    async getStudents() {
      const { items, _meta } = await this.$http.get('/users-student/index-single-field', {
        params: {
          sort: this.sortBy,
          page: this.pagination.currentPage,
          limit: this.pagination.perPage,
          singleField: this.filter.search,
          subject_id: this.filter.subject_ids,
          studentType: this.filter.studentType,
          expand: 'user.teacherStudents.teacher.usersTeachers,user.teacherStudents.teacher.image,user.image,user.usersStudent',
        },
      })
      this.pagination.totalCount = _meta.totalCount
      this.students = items
    },
    async getWidgetsInfo() {
      const { count } = await this.$http.get('/user/count', {
        params: {
          role: 2,
        },
      })
      const { student_leads, student_tutor } = await this.$http.get('/users-teachers/count-student-application')
      this.widgets = { total: count, student_leads, student_tutor }
    },
    fullTeacherName(teacher) {
      return getUserFullName(teacher)
    },
  },
}
</script>

<style scoped>

</style>
