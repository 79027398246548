var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"col-12 col-md-6 col-xl-4"},[_c('number-with-icon',{attrs:{"number":_vm.widgets.total,"icon":"UsersIcon","title":_vm.$t('page.admin_students.widgets.total'),"color":"primary"}})],1),_c('b-col',{staticClass:"col-12 col-md-6 col-xl-4"},[_c('number-with-icon',{attrs:{"number":_vm.widgets.student_leads,"icon":"LinkIcon","title":"Ученики по заявкам","color":"primary"}})],1),_c('b-col',{staticClass:"col-12 col-md-6 col-xl-4"},[_c('number-with-icon',{attrs:{"number":_vm.widgets.student_tutor,"icon":"HeartIcon","title":"Ученики репетиторов","color":"primary"}})],1)],1),_c('b-row',[_c('b-col',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('table-header',{attrs:{"search":"","subjects":"","filter":_vm.filter,"search-updated":_vm.getStudents,"student-type":""}}),_c('b-table',{ref:"teachersTable",attrs:{"striped":"","items":_vm.students,"responsive":"","fields":_vm.tableFields,"primary-key":"id","show-empty":"","empty-text":_vm.$t('page.students.search.labels.no-items'),"sort-by":_vm.filter.sortBy,"sort-desc":_vm.filter.sortDesc},on:{"update:sortBy":function($event){return _vm.$set(_vm.filter, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.filter, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.filter, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.filter, "sortDesc", $event)}},scopedSlots:_vm._u([{key:"cell(username)",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('div',{staticClass:"d-flex flex-row justify-content-start align-items-center"},[(item.user.image)?_c('store-image',{attrs:{"store-id":item.user.image.full_url,"wrapper-class":"table-avatar"}}):_c('b-avatar',{attrs:{"size":"25px"}}),_c('span',{staticClass:"d-block ml-1"},[_vm._v(_vm._s(value))])],1)]}},{key:"cell(teachers)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row justify-content-start align-items-center"},[(item.user.teacherStudents)?[_c('profile-edit-modal',{ref:"editModal",attrs:{"user":item.user.teacherStudents.teacher},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var show = ref.show;
return [_c('a',{attrs:{"href":"#","title":_vm.fullTeacherName(item.user.teacherStudents.teacher)},on:{"click":show}},[(item.user.teacherStudents.teacher.image)?_c('store-image',{attrs:{"store-id":item.user.teacherStudents.teacher.image.full_url,"wrapper-class":"table-avatar"}}):_c('b-avatar',{attrs:{"size":"25px"}})],1)]}}],null,true)})]:_vm._e()],2)]}}])}),_c('table-footer',{attrs:{"pagination-updated":_vm.getStudents,"pagination":_vm.pagination}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }